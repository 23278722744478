
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { AxiosRequestConfig } from "axios";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const loading = ref(true);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const valid = ref(false);
    const token = route.params.token;

    onMounted(() => {
      ApiService.post("/parents/forgotpassword/verifytoken", {
        token,
      } as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.result == "valid") {
            ApiService.setHeaderBuilding(data.db);
            valid.value = true;
          }
          loading.value = false;
        })
        .catch((e) => console.error(e));
    });

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      nPassword: Yup.string()
        .min(4, t("editProfile.passwordNewCondition"))
        .required(t("editProfile.requiredNewPassword")),
      cPassword: Yup.string()
        .min(4, t("editProfile.passwordConfirmationCondition"))
        .required(t("editProfile.requiredConfirmPassword"))
        .oneOf(
          [Yup.ref("nPassword"), null],
          t("editProfile.passwordMustMatch")
        ),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Send login request
      ApiService.patch("/parents/forgotpassword/change", { ...values, token })
        .then(() => {
          // eslint-disable-next-line
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
          Swal.fire({
            text: t("resetpassword.passwordChanged"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            // Go to page after successfully login
            router.push({ name: "sign-in" });
          });
        })
        .catch((e) => {
          console.error(e);
          // Alert then login failed
          Swal.fire({
            text: t("resetpassword.linkExpiredDesc"),
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });

          // eslint-disable-next-line
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      t,
      loading,
      valid,
    };
  },
});
